import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Card, Props as CardProps } from './Card'

export interface Props {
  list: CardProps[]
}

export const Cards = memo(function Cards({ list }: Props) {
  if (list.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap>
        {list.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0 0;
  padding: 0 14.028vw;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 0;
    padding: 0 6.944vw;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin: 1.25rem -7.361vw 0 0;
  position: relative;

  &:before {
    content: '';
    width: 100vw;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 29.888vh;
    bottom: 25.555vh;
    left: -14.028vw;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    &:before {
      left: -6.944vw;
    }
  }

  @media (max-width: 1023px) {
    margin: 0 -1.875rem 0 0;

    &:before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`
