import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  distance?: string
  image?: ImageProps
  title?: string
}

export const Card = memo(function Card({ distance, image, title }: Props) {
  if (!distance || !title) {
    return null
  }

  return (
    <Container>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      {title ? <Title>{title}</Title> : null}
      {distance ? <Distance>{distance}</Distance> : null}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  width: calc(50% - 7.361vw);
  margin: 5.875rem 7.361vw 0 0;
  text-align: left;

  @media (max-width: 1023px) {
    width: calc(50% - 1.875rem);
    margin: 5rem 1.875rem 0 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 3.75rem 0 0;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-bottom: 2.5rem;
  padding-bottom: 75%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin-bottom: 1.875rem;
  }

  @media (max-width: 574px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.5rem;

  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 1.25rem;
  }
`

const Distance = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.2rem;
  margin-top: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.875rem;
  }

  @media (max-width: 574px) {
    margin-top: 0.5rem;
  }
`
